import React from 'react'
import '../Loader.css'
import {PuffLoader} from 'react-spinners'
export default function BigLoader() {
  return (
    <div className="bigloader">
    <div className="loader">
    <PuffLoader color="#70B002" />
  
  </div>
  </div>
  )
}
