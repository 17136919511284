import React from 'react'
import warning_girl from '../../image/warning_girl.png'
import warning_icon from '../../image/warning_icon.png'
import success_girl from '../../image/success_girl.png'
import success_icon from '../../image/success_icon.svg'
import error_girl from '../../image/error_girl.png'
import error_icon from '../../image/error_icon.svg'
import { useStore } from '../../store/Store'
import { useTranslation } from 'react-i18next'
export default function ResultModals({wrong_answers, time, correct_answers, not_touched_answers, btnEnd,successEnd,errorEnd,setbtnEnd, setsuccessEnd, seterrorEnd, finishTest, 
  // faceEnd, face_error_text
}) {
    const user =useStore(state=>state.user)
    const {t}=useTranslation()
    return (
    <>
       <div className={`modal_confirm ${btnEnd?"modal_confirm_open":""}`}>
    <div className='modal_confirm_back'></div>
    <div className='modal_confirm_body'>
      <div className='modal_confirm_img'>
        <img className='girl' src={warning_girl}/>
        <img className='icon' src={warning_icon}/>
      </div>
      <div className='modal_confirm_text'>
           <h1>{t("Ogohlantiramiz!")}</h1>
           <h2>{user!==null?user.lastName+" "+user.firstName:""}</h2>
           <span>{user!==null?user.phone:"+998930820372"}</span>
           <p>{t("Testni qayta ishlash imkoniyati berilmaydi")}</p>
           <p className='pb'><p dangerouslySetInnerHTML={{__html:t("Belgilanmagan javoblar soni")}}/>  <p className='pb_item'> {not_touched_answers} {t("ta")}</p></p>
           <p className='pp'>{t("Testni yakunlashni xohlaysizmi")}</p>
        <div className='confirm_btns'>
            <button  onClick={()=>{setbtnEnd(false)}} className='yoq'>{t("Yo'q")}</button>
            <button  onClick={()=>{setbtnEnd(false);finishTest()}}  className='ha'>{t("Ha")}</button>
           </div>
      </div>
    </div>
   </div>
   <div className={`modal_confirm result_modal modal_confirm_success ${successEnd?"modal_confirm_open":""}`}>
    <div className='modal_confirm_back'></div>
    <div className='modal_confirm_body'>
      <div className='modal_confirm_img'>
        <img className='girl' src={success_girl}/>
        <img className='icon' src={success_icon}/>
      </div>
      <div className='modal_confirm_text'>
           <h1>{t("Tabriklaymiz!")}</h1>
           <h2>{user!==null?user.lastName+" "+user.firstName:""}</h2>
           <span>{user!==null?user.phone:"+998930820372"}</span>
           <p>{t("Siz testdan muvaffaqiyatli o'tdingiz")}</p>
           <div className='confirm_btns'>
          <div className='confirm_items'>
            <div className='confirm_item_color confirm_success'></div>
            <div className='confirm_item_number'>{correct_answers}</div>
          </div>
          
          <div className='confirm_items'>
            <div className='confirm_item_color confirm_error'></div>
            <div className='confirm_item_number'>{wrong_answers}</div>
          </div>
          <div className='confirm_items'>
            <div className='confirm_item_color confirm_none'></div>
            <div className='confirm_item_number'>{not_touched_answers}</div>
          </div>
           </div>
           <div className='confirm_btns time_btn'>
            <button>
            
     
      {parseInt(parseInt(time/60)/10)}
      {parseInt(parseInt(time/60)%10)}
      :
      {parseInt(parseInt(time%60)/10)}
      {parseInt(parseInt(time%60)%10)}
     
            </button>
            </div>
      </div>
      <div className='modal_confirm_right'>
      <button  onClick={()=>{ window.location.href=window.location.href.slice(0, window.location.href.lastIndexOf("/"))}} className='yoq'>{t("Yopish")}</button>
      <button  onClick={()=>{setsuccessEnd(false)}} className='yoq'>{t("Ko'rish")}</button>
      </div>
    </div>
   </div>
   <div className={`modal_confirm result_modal modal_confirm_error ${errorEnd?"modal_confirm_open":""}`}>
    <div className='modal_confirm_back'></div>
    <div className='modal_confirm_body'>
      <div className='modal_confirm_img'>
        <img className='girl' src={error_girl}/>
        <img className='icon' src={error_icon}/>
      </div>
      <div className='modal_confirm_text'>
           <h1>{t("Afsus!")}</h1>
           <h2>{user!==null?user.lastName+" "+user.firstName:""}</h2>
           <span>{user!==null?user.phone:"+998930820372"}</span>
           <p>{t("Siz testdan o'ta olmadingiz...")}</p>
           <div className='confirm_btns'>
          <div className='confirm_items'>
          <div className='confirm_item_color confirm_success'></div>
            <div className='confirm_item_number'>{correct_answers}</div>
          </div>
          
          <div className='confirm_items'>
            <div className='confirm_item_color confirm_error'></div>
            <div className='confirm_item_number'>{wrong_answers}</div>
          </div>
          <div className='confirm_items'>
            <div className='confirm_item_color confirm_none'></div>
            <div className='confirm_item_number'>{not_touched_answers}</div>
          </div>
           </div>
           <div className='confirm_btns time_btn'>
            <button>{parseInt(parseInt(time/60)/10)} 
    {parseInt(parseInt(time/60)%10)} 
    : 
    {parseInt(parseInt(time%60)/10)} 
    {parseInt(parseInt(time%60)%10)} </button>
            </div>
      </div>
      <div className='modal_confirm_right'>
      <button  onClick={()=>{ window.location.href=window.location.href.slice(0, window.location.href.lastIndexOf("/"))}} className='ha'>{t("Yopish")}</button>
      <button  onClick={()=>{seterrorEnd(false)}} className='ha'>{t("Ko'rish")}</button>
      </div>
    </div>
   </div>
   {/* <div className={`modal_confirm result_modal modal_confirm_error ${faceEnd?"modal_confirm_open":""}`}>
    <div className='modal_confirm_back'></div>
    <div className='modal_confirm_body'>
      <div className='modal_confirm_img'>
        <img className='girl' src={error_girl}/>
        <img className='icon' src={error_icon}/>
      </div>
      <div className='modal_confirm_text'>
           <h1>{t("Afsus!")}</h1>
           <h2>{user!==null?user.lastName+" "+user.firstName:""}</h2>
           <span>{user!==null?user.phone:"+998930820372"}</span>
           <p>{face_error_text!==null?face_error_text[t("lang")]:''}</p>
           <p>{t("Hali vaqtingiz bor. Qayta login qilib testni davom ettirishingiz mumkin")}</p>
           <div className='confirm_btns'>
          <div className='confirm_items'>
          <div className='confirm_item_color confirm_success'></div>
            <div className='confirm_item_number'>{correct_answers}</div>
          </div>
          
          <div className='confirm_items'>
            <div className='confirm_item_color confirm_error'></div>
            <div className='confirm_item_number'>{wrong_answers}</div>
          </div>
          <div className='confirm_items'>
            <div className='confirm_item_color confirm_none'></div>
            <div className='confirm_item_number'>{not_touched_answers}</div>
          </div>
           </div>
           <div className='confirm_btns time_btn'>
            <button>{parseInt(parseInt(time/60)/10)} 
    {parseInt(parseInt(time/60)%10)} 
    : 
    {parseInt(parseInt(time%60)/10)} 
    {parseInt(parseInt(time%60)%10)} </button>
            </div>
      </div>
      <div className='modal_confirm_right'>
      <button  onClick={()=>{ window.location.href=window.location.href.slice(0, window.location.href.lastIndexOf("/"))}} className='ha'>{t("Yopish")}</button>
      <button  onClick={()=>{ window.location.href=window.location.href.slice(0, window.location.href.lastIndexOf("/"))}} className='ha'>{t("Kirish")}</button>
      </div>
    </div>
   </div> */}
    </>
  )
}
