import React, { useEffect } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css'
import './Night.css'
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import * as faceapi from 'face-api.js';
import Test from './pages/test/Test';
import { useStore } from './store/Store';
import TestLast from './pages/test/TestLast';
export default function App() {
  const setfaceapi=useStore(state=>state.setfaceapi)
  const dark=useStore(state=>state.dark)

useEffect(()=>{
 
  Promise.all([
    faceapi.nets.tinyFaceDetector.loadFromUri('/face'),
    faceapi.nets.faceLandmark68Net.loadFromUri('/face'),
    faceapi.nets.faceRecognitionNet.loadFromUri('/face'),
    faceapi.nets.ssdMobilenetv1.loadFromUri('/face'),
  ]).then(() => {
    setfaceapi(faceapi)
   
  }).catch(()=>{
   console.log('error')
  })
},[])

  return (
    
    <div className={`${dark?"dark":'light'}`}>

    <Routes>
    <Route path="/" element={<Login/>}/>
      <Route path="/:region/:branch" element={<Login/>}/>
      <Route path="/test" element={<Test/>}/>
      <Route path="/test-last/:user_id" element={<TestLast/>}/>
     
    </Routes>
    </div>
  )
}
