import React from 'react'
import user from '../image/user.svg'
export default function Loader() {
  return (
    <div className='loader'>
        <div className='scan'>
            <div className='face'>
                <div className='dots'>
                    <img src={user}/>
                </div>
            </div>
        </div>
    </div>
  )
}
