
import React, { useEffect, useState } from 'react'

import { useStore } from '../../store/Store'

import moon from '../../image/moon_test.svg'
import sun from '../../image/sun.svg'
import no_image from '../../image/no_image.svg'
import no_image_dark from '../../image/no_image_dark.svg'
import { useTranslation } from 'react-i18next'
import i18n from '../../locales/i18next'
import { Carousel } from 'react-bootstrap'

import { useCookies } from 'react-cookie'
import { getImages, getTestLast, getUser } from '../../host/Config'
import { useParams } from 'react-router-dom';
import BigLoader from '../BigLoader'

export default function TestLast() {

 const user =useStore(state=>state.user)
 const setimages =useStore(state=>state.setimages)
 const images =useStore(state=>state.images)

 const setanswerTests_last =useStore(state=>state.setanswerTests_last)
 const dark =useStore(state=>state.dark)
 const setdark =useStore(state=>state.setdark)
 const bigloader =useStore(state=>state.bigloader)
 const setbigloader =useStore(state=>state.setbigloader)
const tests_last =useStore(state=>state.tests_last)
  const settests_last =useStore(state=>state.settests_last)
  const setuser =useStore(state=>state.setuser)
  const [selected, setselected]=useState(0)
  const [checktime, setchecktime]=useState(false)
  const [wrong_answers, setwrong_answers]=useState(0)
  const [correct_answers, setcorrect_answers]=useState(0)
  const [not_touched_answers, setnot_touched_answers]=useState(0)
  const [time, settime]=useState(1500)
  const [btnEnd, setbtnEnd]=useState(false)
  const [axios_send, setaxios_send]=useState(false)
  const [yesNot, setyesNot]=useState(true)
  const [finishTestBool, setfinishTestBool]=useState(true)
  const [bol, setbol]=useState(false)
  const [confirm_answer, setconfirm_answer]=useState(null)
  const [select_answer, setselect_answer]=useState(null)
  const [select_answer_style, setselect_answer_style]=useState(null)
  const {t}=useTranslation()
  const {user_id}=useParams()
 
  const [cookies, setCookie, removeCookie]=useCookies()

  

   const getTestDatafinish=async()=>{
    try{
      var res2=await getTestLast(user_id)
        settests_last(res2.data)
        setwrong_answers(res2.data.wrong_answers)
        setcorrect_answers(res2.data.correct_answers)
        setnot_touched_answers(res2.data.not_touched_answers)
        settime(1500-res2.data.timer)
        setbigloader(false)
        try{
           var res4=await getImages(res2.data.id)
           setimages(res4.data)
        }catch(err){
          console.log("error")
        }
      
    }catch(err){
      console.log('error')   
    }
    
   }

  useEffect(()=>{
    document.addEventListener('keydown', handleKeyPress);
      i18n.changeLanguage(cookies.langTest?cookies.langTest:"o'z")
   
      if((user===null) && (user_id===undefined)){
     window.location.href=window.location.href.slice(0, window.location.href.lastIndexOf("/test-last"))
      }else{
        if(user===null && user_id!==undefined){
          setbigloader(true)
          getUser(user_id).then(res1=>{
            setuser(res1.data)
           }).catch(err=>{
             console.log("salom")
             window.location.href=window.location.href.slice(0, window.location.href.lastIndexOf("/test-last"))
         })
         getTestDatafinish()
        
        }
        if(user!==null){
           setwrong_answers(tests_last.wrong_answers)
            setcorrect_answers(tests_last.correct_answers)
            setnot_touched_answers(tests_last.not_touched_answers)
            settime(1500-tests_last.timer)
            setbigloader(false)
            getImages(tests_last.id).then(res4=>{
              setimages(res4.data)
             }).catch(err=>{
              console.log(err)
             })
        }
       
        } i18n.changeLanguage(cookies.langTest?cookies.langTest:"o'z")
   
   
  
return(()=>{
      document.removeEventListener('keydown', handleKeyPress);
    })
    },[])
  
    function handleKeyPress(event) {
    
      var a=112
     
      var teg=document.getElementsByClassName('confirm_answer_bool')[0].innerHTML==='true'
     
      if(event.keyCode===37){
        if(teg){
          setyesNot(false)
         }else{
          if(Number(document.getElementsByClassName("selected_selected")[0].innerHTML)!==0){
            setselected(Number(document.getElementsByClassName("selected_selected")[0].innerHTML)-1)
          }
         }
      
      }
      if(event.keyCode===39){
        if(teg){
          setyesNot(true)
         }else{
          if(Number(document.getElementsByClassName("selected_selected")[0].innerHTML)!==Number(document.getElementsByClassName("test_length")[0].innerHTML)){
            setselected(Number(document.getElementsByClassName("selected_selected")[0].innerHTML)+1)
          }
         }
      

      }
      
}

    const addSelected=()=>{
      if(selected!==19){
        setselected(selected+1)
      }else{
        setselected(0)
      }
    }
    const removeSelected=()=>{
      if(selected!==0){
        setselected(selected-1)
      }else{
        setselected(19)
      }
    }
    const selectSelected=(key)=>{
     setselected(key)
    }
    
     
    const questionTiltle=(a)=>{
       var b=JSON.parse(a)
       var a={
        "ru":b.ru,
      "uz":b.uzb,
      "krill":b.uzc,
       }
       return(a)
    }
    
   
  return (
    <>
    <p className='selected_length' style={{display:'none'}}>{tests_last!==null?tests_last.questionsList[selected].testAnswers.length:''}</p>
        <p className='selected_answer' style={{display:'none'}}>{tests_last!==null?tests_last.questionsList[selected].user_answerId:''}</p>
        <p className='selected_question' style={{display:'none'}}>{tests_last!==null?tests_last.questionsList[selected].id:''}</p>
        <p className='test_length' style={{display:'none'}}>{tests_last!==null?tests_last.questionsList.length-1:''}</p>
        <p className='selected_test' style={{display:'none'}}>{tests_last!==null?tests_last.id:''}</p>
        <p className='selected_time' style={{display:'none'}}>{time}</p>
        <p className='selected_selected' style={{display:'none'}}>{selected}</p>
        <p className='wrong_answers' style={{display:'none'}}>{wrong_answers}</p>
        <p className='correct_answers' style={{display:'none'}}>{correct_answers}</p>
        <p className='checker' style={{display:'none'}}>{checktime?'true':'false'}</p>
        <p className='not_touched_answers' style={{display:'none'}}>{not_touched_answers}</p>
        <p className='finishTestBool' style={{display:'none'}}>{`${finishTestBool}`}</p>
        <p className='confirm_answer_bool' style={{display:'none'}}>{`${confirm_answer!==null}`}</p>
        <p className='yesNot' style={{display:'none'}}>{`${yesNot}`}</p>
        <p className='select_answer_id' style={{display:'none'}}>{`${select_answer}`}</p>
  <>
    {/* {user===null?<Navigate to="/"/>:<></>} */}
    {bigloader?<BigLoader/>:<></>}
   <div className='test'>
    <div className='test_logo'>
     
    </div>
     
    <div className='test_number'>
    <div className='test_arrow test_arrow_left'  onClick={()=>{if(!axios_send){removeSelected()}}}></div>
    <div className='test_number_box'>
    {tests_last!==null && !!tests_last.questionsList?tests_last.questionsList.map((item, key)=>{
      return(<div key={key} onClick={()=>{if(!axios_send){selectSelected(key)}}} className={` test_number_item ${item.correct_answerId!==0 && item.correct_answerId!==item.user_answerId?'test_number_error':''} ${key===selected?'test_number_select':''} ${item.correct_answerId!==0 && item.correct_answerId===item.user_answerId?'test_number_success':''}`}>{key+1}</div>)
    }):<></>}
    </div>
    <div className='test_arrow test_arrow_right'  onClick={()=>{if(!axios_send){addSelected()}}}></div>
</div>
<div className={`test_header_params ${finishTestBool?"finished_test_header":""}`}>
  <div className={`test_lang ${t('lang')==='lang'?'uz':t('lang')}`}>
    <div  onClick={()=>{i18n.changeLanguage("o'z")}} className='test_lang_item uz'>O'Z</div>
    <div  onClick={()=>{i18n.changeLanguage("ўз")}} className='test_lang_item krill'>ЎЗ</div>
    <div onClick={()=>{i18n.changeLanguage("ру")}} className='test_lang_item ru'>РУ</div>
  </div>
  {finishTestBool? <div onClick={()=>{setdark(!dark)}} className='dark_item'>
    <img src={dark?sun:moon}/>
  </div>:<></>}
  <div className={`time ${finishTestBool?'A006ecb':time<=15*60 && time>5*60?"FFB501":time<=5*60?"E73232":"A6EA903"}`}>
    <h1>
     
    <span>{parseInt(parseInt(time/60)/10)}</span>
    <span>{parseInt(parseInt(time/60)%10)}</span>
    <span>:</span>
    <span>{parseInt(parseInt(time%60)/10)}</span>
    <span>{parseInt(parseInt(time%60)%10)}</span>
    </h1>
    <div className='time_bg' style={{width:`${time*100/(25*60)}%`, background:time<=15*60 && time>5*60?"#FFB501":time<=5*60?"#E73232":"#6EA903"}}></div>
  </div>
  {!finishTestBool? <div onClick={()=>{setdark(!dark)}} className='dark_item'>
    <img src={dark?sun:moon}/>
  </div>:<></>}
  {finishTestBool?<div className={`final_btn chiqish`} onClick={()=>{ window.location.href=window.location.href.slice(0, window.location.href.lastIndexOf("/test-last"))}}>
   {t("Chiqish")}
  </div>:<></>}
</div>
<div className='test_video'>
  <div className='test_video_cam'>
  <div className='faceBox'>
   {images!==null ?images.length!==0? <div className='images_finish'>
       <Carousel>
       {images.map((item)=>{
        return(<Carousel.Item interval={3000}>
          <img
            className="d-block w-100"
            src={"data:image/png;base64,"+item.imageBase64}
            alt="First slide"
          />
         
         </Carousel.Item>)
 
})}
     
     
    </Carousel>
     
    </div>:<></>:<></>
    }
     

    </div>
  
  </div>
  <div className='test_video_text'>
    {user!==null?<><h1>{user.lastName[0].toUpperCase()}{user.lastName.slice(1).toLowerCase()} {user.firstName[0].toUpperCase()}{user.firstName.slice(1).toLowerCase()}</h1><p>{user.phone}</p></>:''}
    
    </div>
   
   </div>
   <div className={`${checktime?'hidden_text':''} test_question`}>
    <div className={`question_card ${tests_last!==null && !!tests_last.questionsList?tests_last.questionsList[selected].user_answerId===0?"":tests_last.questionsList[selected].user_answerId===tests_last.questionsList[selected].correct_answerId?"question_card_correct":"question_card_error":''}`}>{selected+1}</div>
    <p dangerouslySetInnerHTML={{__html:tests_last!==null && !!tests_last.questionsList?questionTiltle(tests_last.questionsList[selected].question)[t("lang")]:''}}></p>
  </div>
   <div className='test_image'>
    <img src={tests_last!==null && !!tests_last.questionsList?tests_last.questionsList[selected].media!==null && tests_last.questionsList[selected].media!==''?tests_last.questionsList[selected].media:dark?no_image_dark:no_image:dark?no_image_dark:no_image}/>
   </div>
   <div className='test_answers'>
   {tests_last!==null && !!tests_last.questionsList?tests_last.questionsList[selected].testAnswers.map((item, key)=>{
              return(
              <div  key={key} className='test_answers_box'>
                <p>F{key+1}</p>
              <div id={`${item.id}`}  key={key} dangerouslySetInnerHTML={{__html:questionTiltle(item.answer)[t("lang")]}} className={`${checktime?'hidden_text':''} test_answers_item ${tests_last.questionsList[selected].user_answerId===0 && (!finishTestBool)?"t_a":""} ${!finishTestBool && select_answer_style===key?"p_a":""} ${tests_last.questionsList[selected].correct_answerId!==0 && tests_last.questionsList[selected].user_answerId===tests_last.questionsList[selected].correct_answerId && tests_last.questionsList[selected].correct_answerId===item.id?'success_answer':""} ${tests_last.questionsList[selected].correct_answerId!==0 && item.id===tests_last.questionsList[selected].user_answerId?'error_answer':""} ${select_answer===item.id?"select_answer":''}`}></div></div>)
      }):<></>}
      
   </div>
   </div>
</>
    </>
   
  )
}
