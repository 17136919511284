import React from 'react'
import moon from '../image/moon.svg'
import back from '../image/back.svg'
import back_dark from '../image/back_dark.svg'
import sun from '../image/sun.svg'
import { useStore } from '../store/Store'
import { useEffect } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import i18n from '../locales/i18next';
import { useState } from 'react'
import { useCookies } from 'react-cookie'
import { useTranslation } from 'react-i18next'
export default function Header() {
  const {t}=useTranslation()
  const setlevel=useStore(state=>state.setlevel)
  const level=useStore(state=>state.level)
  const faceDetectionLogin=useStore(state=>state.faceDetectionLogin)
  const dark=useStore(state=>state.dark)
  const setuser=useStore(state=>state.setuser)
    const setdark=useStore(state=>state.setdark)
    const [cookie, setCookie]=useCookies()
    const [til, settil] = useState(cookie.langTest?cookie.langTest:"o'z");
    const changeLanguageA=()=>{
        if(til==="o'z"){
          settil("ўз")
          i18n.changeLanguage("ўз")
          setCookie("lang", 'ўз')
        }
        if(til==='ўз'){
            settil("ру")
            i18n.changeLanguage("ру")
            setCookie("lang", "ру")
        }
        if(til==='ру'){
          settil("o'z")
          i18n.changeLanguage("o'z")
          setCookie("lang", "o'z")
        }
       
       }
      
       useEffect(()=>{
        i18n.changeLanguage(cookie.langTest?cookie.langTest:"o'z")
      },[])
  return (
    <div className='headerBox'>
<div className='header_img_main'></div>
      
    <div className='header_btns'>
    {level? <OverlayTrigger
      placement="bottom"
      key="1"
      delay={{ show: 250, hide: 250 }}
      overlay={ <Tooltip id="tooltip-bottom">
      {t("Ortga qaytish")}
    </Tooltip>}>
   <div onClick={()=>{if(level===2){ setlevel(null);if(faceDetectionLogin){setuser(null)}}else{setlevel(null)}}} className='header_btn_item'>
    <img className='back_img_header' src={back}/>
    <img className='back_img_header_dark' src={back_dark}/>
     </div>
    
     </OverlayTrigger>  :<></>}
     <OverlayTrigger
      placement="bottom"
      key="2"
      delay={{ show: 250, hide: 250 }}
      overlay={ <Tooltip id="tooltip-bottom">
      {t("Rejim o'zgartirish")}
    </Tooltip>}>
     <div className='header_btn_item' onClick={()=>{setdark(!dark)}}>
        {dark?<img src={sun}/>:<img src={moon}/>}
     </div>   
     </OverlayTrigger> 
     <OverlayTrigger
      placement="bottom"
      key="3"
      delay={{ show: 250, hide: 250 }}
      overlay={ <Tooltip id="tooltip-bottom">
      {t("Til o'zgartirish")}
    </Tooltip>}>
    <div onClick={changeLanguageA} className='header_btn_item ad'>
        {til}
    </div> 
    </OverlayTrigger> 
    </div>    
    </div>
  )
}
