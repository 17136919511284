import React, { useEffect, useRef, useState } from 'react'
import Webcam from 'react-webcam'
import Loader from './Loader'
import Main from './Main'
import success_circle from '../image/success_circle.svg'
import camera_img from '../image/camera.svg'
import errorimg from '../image/error.svg'
import userImage from '../image/15.jpg'
import { Link, Navigate} from 'react-router-dom'
import { checkFace, getLogin, getTestLast, getTest, getUser } from '../host/Config'
import { useTranslation } from 'react-i18next'
import { useStore } from '../store/Store'
import { apiFace } from '../host/Host'
import { message } from 'antd'
import login_error from '../image/login_error.svg'

const videoConstraints = {
  width: 400,
  height: 400,
  facingMode: 'user',
}
export default function Login() {
  const videoRef=useRef()
  const {t} =useTranslation()
  const [password, setpassword]=useState('')
  const [username, setusername]=useState("")
  const [phone, setphone]=useState("+998 ")
  const [error, seterror]=useState(false)
  const [test_f_test, settest_f_test]=useState(false)
  const [loader, setloader]=useState(false)
  const [camera, setcamera]=useState(true)
  const video=useStore(state=>state.video)
  const user=useStore(state=>state.user)
  const faceDetectionLogin=useStore(state=>state.faceDetectionLogin)
  
  const setuser=useStore(state=>state.setuser)
  const tests_last=useStore(state=>state.tests_last)
  const settests_last=useStore(state=>state.settests_last)
  const tests=useStore(state=>state.tests)
  const settests=useStore(state=>state.settests)
  const not_touched_answers=useStore(state=>state.not_touched_answers)
  const setnot_touched_answers=useStore(state=>state.setnot_touched_answers)
  const correct_answers=useStore(state=>state.correct_answers)
  const setcorrect_answers=useStore(state=>state.setcorrect_answers)
  const wrong_answers=useStore(state=>state.wrong_answers)
  const setwrong_answers=useStore(state=>state.setwrong_answers)
  const level=useStore(state=>state.level)
  const faceapi=useStore(state=>state.faceapi)
  const setdetectionsImage=useStore(state=>state.setdetectionsImage)
  const detectionsImage=useStore(state=>state.detectionsImage)
const setlevel=useStore(state=>state.setlevel)
const setvideo=useStore(state=>state.setvideo)

const imgRef=useRef()



const faceDetection = async () => {
  converWhiteToBlack()
}

const converWhiteToBlack=async()=>{
  if(document.querySelector('.faceDetectionMain').innerHTML==='true' && document.querySelector('.test_f_test').innerHTML==='true'){
     const canvas = document.getElementById('imageCanvas');
  canvas.width = imgRef.current.naturalWidth;
  canvas.height = imgRef.current.naturalHeight;
  
  const ctx = canvas.getContext('2d');
  ctx.drawImage(imgRef.current, 0, 0);
  
  // Preprocess the image by adjusting brightness/contrast levels
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
  const data = imageData.data;
  
  for (let i = 0; i < imageData.data.length; i += 4) {
    
    imageData.data[i] *= 1.4;
    imageData.data[i + 1] *= 1.4;
    imageData.data[i + 2] *= 1.4;
  }
  
  ctx.putImageData(imageData, 0, 0);
    
      const detectionsImageLoc = await faceapi.detectSingleFace(canvas)
      .withFaceLandmarks()
      .withFaceDescriptor()
      setdetectionsImage(detectionsImageLoc)
      console.log(detectionsImageLoc, "AAAAAAAAAAAAAAAAAAA")
      setloader(false)
    }else{
      setdetectionsImage('null')
      setloader(false)
    }

   

}
const getTestCon=(id)=>{

  getTest(id).then(res2=>{
   if(res2.data.status!==-1 && res2.data.status!==1){
    settests(res2.data)
   }else{
          window.location.href=window.location.href
        }
   
   
   }).catch(err=>{
     seterror(true)
    
  })
}
  useEffect(()=>{
    
    if(faceapi!==null && level===2 && user!==null){

  setTimeout(()=>{
    faceDetection()
  },700)
     
             
    }
   if(level===1){
    startVideo()
   }
  
    
  }, [level, faceapi, user])
  const  startVideo=()=>{
    navigator.mediaDevices.getUserMedia({ video: true    })
    .then((currentStream) => {
      
      console.error("kamera yoqildi")      
     }).catch((err) => {
      setcamera(false)
            console.error("kamera yoqilmadi")
    });
  }
  

  const getScreenShot=async()=>{
  
    setloader(true)
      const pictureSrc = videoRef.current.getScreenshot()
      var picture=pictureSrc.replaceAll("data:image/jpeg;base64,", "data:image/octet-stream;base64,")
      if(username.length>=4){
        var config={username, unknown:picture}
      }else if(phone.length===19){
        var config={unknown:picture, phone:phone.replaceAll("+","").replaceAll(" ","").replaceAll("(","").replaceAll(")","").replaceAll("-","")}
      }
      try{
        var res=await checkFace(config)
        seterror(false)
        setloader(false)
        settest_f_test(res.data.fTestPermission)
        setuser(res.data)
        settests_last(null)
        if(!res.data.fTestPermission){
          try{
            var res2=await getTestLast(res.data.id)
              settests_last(res2.data)
             
              setwrong_answers(res2.data.wrong_answers)
              setcorrect_answers(res2.data.correct_answers)
              setnot_touched_answers(res2.data.not_touched_answers)
          }catch(err){
            seterror(true)
            setloader(false)
            setuser(null)
          }
        }
      }catch(err){
        seterror(true)
        setloader(false)
      }
     
   }

   const loginByPassword=()=>{
    if(username.length!==0 && password.length!==0){
      getLogin({password, username:username, selectedRole:0}).then(res=>{
        var g=res.data.userData
        if(g.role.id===7){
          getUser(g.id).then(res1=>{
            setuser(res1.data)
            settests_last(null)
            settest_f_test(g.ftest_permission)
            if(!g.ftest_permission){
                    getTestLast(res1.data.id).then(res2=>{
                      console.log("salom dhsjsdh")
                    settests_last(res2.data)
                    setwrong_answers(res2.data.wrong_answers)
                    setcorrect_answers(res2.data.correct_answers)
                    setnot_touched_answers(res2.data.not_touched_answers)
                    seterror(false)
            setvideo(true);
            setlevel(1)
                  }).catch(err=>{
                    message.error({content:t("no_student_test"), duration:4, icon:<img src={login_error}/>})
                  })
               
            }else{
              seterror(false)
            setvideo(true);
            setlevel(1)
            }

          }).catch(err=>{
            message.error({content:t("Shaxsingiz tasdiqlanmadi!"), duration:4, icon:<img src={login_error}/>})
          })
   
        }else{
          message.error({content:t("Shaxsingiz tasdiqlanmadi!"), duration:4, icon:<img src={login_error}/>})
        }
      }).catch(err=>{
        message.error({content:t("Shaxsingiz tasdiqlanmadi!"), duration:4, icon:<img src={login_error}/>})
      })
    
  }
   }
 const editFormatPhone=(a)=>{

  if(a!=null){
    var res=`+${a.slice(0,3)} (${a.slice(3,5)}) ${a.slice(5,8)}-${a.slice(8,10)}-${a.slice(10,12)}`
    return(res)
  }else{
    return("-")
  }

  }
  const formatDate=(t_date)=>{
    if(t_date!=null){
     
      const date = new Date(t_date);
      
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear().toString();
      
      const formattedDate = `${day}-${month}-${year}`;
      return(formattedDate)
    }else{
      return('-')
    }
  }
   return (
    <>
    {tests!==null?<Navigate to="/test"/>: <div className={`login ${level!==0?"main_none":''}`}>
    
    <Main  level={level} setlevel={setlevel} loginByPassword={loginByPassword} username={username} setusername={setusername} password={password} setpassword={setpassword} phone={phone} setphone={setphone}/>
    <div className={`video_box ${level===0?'video_box_none':''} ${level===2?'video_box_top':''}  ${error || user!==null?"left_video_text":""}`}>
    <div className='video_col'>
          <div className='video'>
            {
            !video?<></>: <>
            {camera?  <Webcam 
            mirrored={true}
        ref={videoRef}
        audio={false}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        />: <div className={`kamera_error`}>
        <img src={camera_img}/>
        <h1>{t("Kamerangiz yoqilmagan!")}</h1>
        <span>{t("Iltimos, kamerangiz yoqilganiga va brauzerda kameradan foydalanish uchun ruxsat berilganligiga ishonch hosil qiling va qayta urinib ko’ring")}</span>
        </div> }
          
       {loader? <Loader/>:<></>}
       
        </>}
           
           
       
       </div>
       {
         user===null? <button disabled={loader} onClick={getScreenShot} className={`btn_cam btn_cam_sm ${error?"btn_warning":''}`}>{error?t("Shaxsingizni qayta tasdiqlang"):t("Shaxsingizni tasdiqlang")}</button>:
        <Link onClick={()=>{setlevel(2)}} disabled={loader} className={`btn_cam btn_cam_sm btn_test`}>{t("Testga o'tish")}</Link>
       }
     
      </div>    

      <div className={`video_text success ${user!==null?"show_text":""}`}>
        <img src={success_circle}/>
        <h1>{t("Shaxsingiz tasdiqlandi!")}</h1>
        <div className='video_text_item'><div className='video_text_img user_icon'></div><p>{user!==null?user.lastName + " "+ user.firstName:<></>}</p></div>
        <div className='video_text_item'><div className='video_text_img passport'></div><p>{user!==null?user.passport:<></>}</p></div>
        <div className='video_text_item mini_img'><div className='video_text_img tel'></div><p>{user!==null?editFormatPhone(user.phone):<></>}</p></div>
        <div className='video_text_item'><div className='video_text_img group'></div><p>{user!==null?user.groupName:<></>}</p></div>
        </div> 
        <div className={`video_text error ${error?"show_text":""}`}>
        <img src={errorimg}/>
       <h1>{t("Shaxsingiz tasdiqlanmadi!")}</h1>
       <span>{t("Iltimos, qaytadan urinib ko‘ring yoki moderatorga murojaat qiling")}</span>
        </div>
        </div>
       <div className={`video_box test_box_video ${level!==2?'video_box_none':''}`}>
       {test_f_test?<div className='video_col'>
     
     <div className='video test_text_box'>
    <h1>{t('Nazariy Imtihon')}</h1>
    <p><div>{t('Savollar soni')}: </div><span>20</span></p>
    <p><div>{t('O‘tish bali')}: </div><span>18</span></p>
    <p><div>{t('Xato qilish mumkin')}: </div><span>2</span></p>
    <p><div>{t('Berilgan vaqt')}: </div><span>25 {t("daqiqa")}</span></p>
  </div>
  {user===null? <button disabled={loader} onClick={getScreenShot} className={`btn_cam btn_cam_sm ${error?"btn_warning":''}`}>{error?t("Shaxsingizni qayta tasdiqlang"):t("Shaxsingizni tasdiqlang")}</button>:
   <button onClick={()=>{getTestCon(user.id)}} to={"test"} disabled={loader} className={`btn_cam btn_cam_sm btn_test ${detectionsImage===null?"error_go_test":""}`}>{detectionsImage===null?t("Ma'lumotlar yuklanmoqda"):t("Testni boshlash")}</button>}
 </div>:<div className='video_col '>
     
     <div className='video test_text_box last_text_box_text'>
    {tests_last!==null?tests_last.status===1?<h1 className='active_h1'>{t("Testdan o'tgan")}</h1>:<h1 className='danger_h1'>{t("Testdan o'tmagan")}</h1>:''}
    <p><div>{t('Savollar soni')}: </div><span>{tests_last!==null?tests_last.questionsList.length:''}</span></p>
    <p><div>{t("To'g'ri javoblar")}: </div><span>{correct_answers}</span></p>
    <p><div>{t("Noto'g'ri javoblar")}: </div><span>{wrong_answers}</span></p>
    <p><div>{t('Topshirilgan vaqt')}: </div><span>{tests_last!==null?parseInt((1500-tests_last.timer)/60):''} {t("daqiqa")}</span></p>
    <p><div>{t('Topshirilgan sana')}: </div><span>{tests_last!==null?formatDate(tests_last.created_on):''}</span></p>
  </div>
  {user===null? <button disabled={loader} onClick={getScreenShot} className={`btn_cam btn_cam_sm ${error?"btn_warning":''}`}>{error?t("Shaxsingizni qayta tasdiqlang"):t("Shaxsingizni tasdiqlang")}</button>:
   <Link  to={"test-last/"+user.id} disabled={loader} className={`btn_cam btn_cam_sm btn_test ${detectionsImage===null?"error_go_test":""}`}>{detectionsImage===null?t("Ma'lumotlar yuklanmoqda"):t("Natijani ko'rish")}</Link>}
 </div>}    
     </div>
      {user!==null? <img ref={imgRef}  style={{display:'none'}} id="faceImg" crossOrigin='anonymus'  src={
apiFace+user.avatar
// userImage
} alt="" />:<></>}

<p style={{display:'none'}} className='faceDetectionLogin'>{`${faceDetectionLogin}`}</p>
<p style={{display:'none'}} className='test_f_test'>{`${test_f_test}`}</p>
<p style={{display:'none'}} className='faceDetectionMain'>{user!==null?`${user.faceDetection}`:'false'}</p>
<canvas style={{display:'none'}}  id="imageCanvas"></canvas>
{/* <canvas style={{width:'200px', position:'fixed', top:'0px', left:'0px', zIndex:'4444'}}  id="imageCanvas"></canvas> */}
  </div>}
   </>
  )
}
