import React, { useEffect, useRef, useState } from 'react'
import userImage from '../../image/15.jpg'
import { useStore } from '../../store/Store'
import { api, apiFace } from '../../host/Host'
import Loader from '../Loader'
import axios from 'axios'
import CaptureScreenshot from './CaptureScreenshot'
import { useTranslation } from 'react-i18next'
import { Carousel } from 'react-bootstrap'

export default function FaceDetection({
  // faceEndTest,
  t_id, finishTestBool, faceapi, bol, setbol, 
  // setface_error_text,
  images,
  checktime, setchecktime,
  time,
  // checker, 
  // setchecker
}) {
    const videoRef=useRef()
   const {t}=useTranslation()
   const [boshlandi, setboshlandi]=useState(false)
    const [errortext, seterrortext]=useState(null)
  
    const [capture, setcapture]=useState(false)
    const setbigloader=useStore(state=>state.setbigloader)
    const bigloader=useStore(state=>state.bigloader)
  
    const detectionsImage=useStore(state=>state.detectionsImage)
    const setdetectionsImage=useStore(state=>state.setdetectionsImage)
    const error_text_1={
      uz:"Kamera yuzingizni ko'rmayapti<br/><span>Kamerada yuzingiz ko'rinib turadigan holatda o'tirishingizni so'raymiz</span>",
      ru:"Камера не видит ваше лицо<br/><span>Мы просим вас сидеть так, чтобы ваше лицо было видно в камере</span>",
      krill:"Камера юзингизни кўрмаяпти<br/><span>Камерада юзингиз кўриниб турадиган ҳолатда ўтиришингизни сўраймиз</span>",
    }
    // const face_text_1={
    //   uz:"Kamera sizni ko'rmadi.",
    //   ru:"Камера тебя не увидела.",
    //   krill:"Камера сизни кўрмади.",
    // }
    
    const error_text_3={
      uz:"Kamera sizni tanimayapti<br/><span>Kamerada yuzingiz ko'rinib turadigan holatda o'tirishingizni so'raymiz</span>",
      ru:"Камера не распознает вас<br/><span>Мы просим вас сесть так, чтобы ваше лицо было видно камере</span>",
      krill:"Камера сизни танимаяпти<br/><span>Камерада юзингиз кўриниб турадиган ҳолатда ўтиришингизни сўраймиз</span>",
    }
    // const face_text_3={
    //   uz:"Kamera sizni tanimadi.",
    //   ru:"Камера не узнала вас.",
    //   krill:"Камера сизни танимади.",
    // }
    
    const user=useStore(state=>state.user)
    const imgRef=useRef()
    var a=null
    var detectImage=detectionsImage
    const faceDetectionFunc = async () => {
      converWhiteToBlack()
      console.log(imgRef.current.currentSrc, faceapi)
     }
  const converWhiteToBlack=async()=>{
    if(document.querySelector('.faceDetection').innerHTML==='true'){
    const canvas = document.getElementById('imageCanvas');
    canvas.width = imgRef.current.naturalWidth;
    canvas.height = imgRef.current.naturalHeight;
    
    const ctx = canvas.getContext('2d');
    ctx.drawImage(imgRef.current, 0, 0);
    
    // Preprocess the image by adjusting brightness/contrast levels
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const data = imageData.data;
    
    for (let i = 0; i < imageData.data.length; i += 4) {
      
      imageData.data[i] *= 1.4;
      imageData.data[i + 1] *= 1.4;
      imageData.data[i + 2] *= 1.4;
    }
    
    ctx.putImageData(imageData, 0, 0);
      
        console.log(imgRef.current.src)
        const detectionsImageLoc = await faceapi.detectSingleFace(canvas)
        .withFaceLandmarks()
        .withFaceDescriptor()
        setdetectionsImage(detectionsImageLoc)
        detectImage=detectionsImageLoc
        console.log(detectImage, detectionsImageLoc, imgRef)
       
      }
   
  }
 
    useEffect(()=>{
     console.log(detectImage,user, faceapi, bol, document.querySelector('.faceDetection').innerHTML)
      if(document.querySelector('.boshlandi').innerHTML==="false"){
      
        if(detectImage===null && user!==null && faceapi!==null){
         
          if(document.querySelector('.faceDetection').innerHTML==='false'){
            setboshlandi(true)
             setbigloader(false)
            
          }else{
           faceDetectionFunc()
          }
         }
          if(faceapi!==null){
            user && videoRef  && startVideo()          
   
           
           }
         
      }
      if(bol && document.querySelector('.faceDetection').innerHTML==='false'){
        setbol(false)   
        setcapture(true)
      }
     
   },[faceapi, user, bol])
   
    const startVideo = (signal) => {
       navigator.mediaDevices.getUserMedia({ video: true    })
       .then((currentStream) => {
      console.log(detectImage, videoRef)
         videoRef.current.srcObject = currentStream;
         detectImage && videoRef && faceDetection();
        }).catch((err) => {
          console.error("kamera yoqilmadi")
       });
     
          
       }
       const error_text_2=(l)=>{
        return({
          uz:"Kamerada "+l+" ta inson ko'rinyapti<br/><span>O'zingizdan boshqa insonlar kompyuterdan uzoqlashsin</span>",
          ru:"На камере видны "+l+" человека<br/><span>Не подпускайте к компьютеру других людей, кроме вас</span>",
          krill:"Камерада "+l+" та инсон кўриняпти<br/><span>Ўзингиздан бошқа инсонлар компьютердан узоқлашсин</span>"
        })
       }
    
    const faceDetection = async () => {
      setbigloader(false)
 
      if(document.querySelector('.faceDetection').innerHTML==='true'){
a=setInterval(async() => {
          const detections = await faceapi.detectAllFaces(videoRef.current,new faceapi.TinyFaceDetectorOptions())
                     .withFaceLandmarks()
                     .withFaceDescriptors();
                    //  var check=document.querySelector('#text').innerHTML
                    console.log(detections)
                     if (!detections) {
                      seterrortext(error_text_1)
                      // setface_error_text(face_text_1)
                      setchecktime(true)  
                      // if(check==="false"){
                        //  setchecktime(true)
                        //  checkTimeFunc()
                        // }
                        return
                     }
                     else{
                    
                      if(detections.length===0){
                        seterrortext(error_text_1)
                      // setface_error_text(face_text_1)
                      setchecktime(true)  
                      // if(check==="false"){
                        //     setchecktime(true)
                        //      checkTimeFunc()
                        // }
                        return
                      }
                      if(detections.length>1){
                        seterrortext(error_text_2(detections.length))
                        // setface_error_text(face_text_2(detections.length))
                      setchecktime(true)
                        //   if(check==="false"){
                      //     setchecktime(true)
                      //      checkTimeFunc()
                      // }
                      return
                      }
                     }
                    const faceMatcher = new faceapi.FaceMatcher(detections)
                     if (!!detectImage) {
                         const bestMatch = faceMatcher.findBestMatch(detectImage.descriptor)
                       
                         if(bestMatch["_label"]==="unknown"){
                          seterrortext(error_text_3)
                          // setface_error_text(face_text_3)
                            setchecktime(true)
                          // if(check==="false"){
                        //   setchecktime(true)
                        //    checkTimeFunc()
                        // }
                         }
                         if(bestMatch["_label"]!=="unknown"){
                            setchecktime(false)
                            if(document.querySelector('.bol').innerHTML==="true"){
                              setbol(false)   
                              setcapture(true)
                                 
                            }
                         }
               }  
               if(document.querySelector('.finishTestBool').innerHTML==="true"){
                 clearInterval(a)
               }
          }, 4000);
        }
  };     
      // const checkTimeFunc=()=>{
      //   var i=30
      //   // setcapture(true)
      //   var b=setInterval(() => {
      //       var check=document.querySelector('#text').innerHTML
            
      //       if(check==='true'){
      //         if(i<=0){
      //           setchecker(0)
      //         }
      //         setchecker(i)
      //           i--
      //       }else{
      //           setchecker(null)
      //           clearInterval(b)
      //       }
      //       if(i===0){
      //         faceEndTest()
      //       }
            
      //   }, 1000);
      // }
    const  resetCapture = () => {setcapture(false)}
   
  return (
    <div className='faceBox'>
   {capture?<CaptureScreenshot t_id={t_id} video={document.querySelector('.videoTag')}  resetCapture={resetCapture} videoWidth={!!videoRef.current?videoRef.current.videoWidth/3:200} videoHeight={!!videoRef.current?videoRef.current.videoHeight/3:150}/>:<></>}
   
   <video className='videoTag' crossOrigin='anonymous' style={{height:'100%', width:'auto', transform:'rotateY(180deg)'}} ref={videoRef} autoPlay > </video>

   {images!==null ?   images.length!==0? <div className='images_finish'>
       <Carousel>
       {images.map((item)=>{
        return(<Carousel.Item interval={3000}>
          <img
            className="d-block w-100"
            src={"data:image/png;base64,"+item.imageBase64}
            alt="First slide"
          />
         
         </Carousel.Item>)
 
})}
     
     
    </Carousel>
     
    </div>:<></>:<></>
    }
      
      {bigloader? <Loader/>:<></>}
     
      <div className={`tooltip_face ${checktime?"tooltip_face_active":''}`}>
      
      <div><h1>
     
     <span>{parseInt(parseInt(time/60)/10)}</span>
     <span>{parseInt(parseInt(time/60)%10)}</span>
     <span>:</span>
     <span>{parseInt(parseInt(time%60)/10)}</span>
     <span>{parseInt(parseInt(time%60)%10)}</span>
     </h1></div><p dangerouslySetInnerHTML={{__html:errortext!==null?errortext[t('lang')]:''}}></p>
 </div>
 {user!==null?<img ref={imgRef} id="faceImg" crossOrigin='anonymus' style={{display:'none'}} src={
apiFace+user.avatar
// userImage
} alt="" />:<></>}
<canvas style={{display:'none'}} id="imageCanvas"></canvas>
<p className='bol' style={{display:'none'}}>{`${bol}`}</p>
        <p className='finishTestBool' style={{display:'none'}}>{`${finishTestBool}`}</p>
        <p className='boshlandi' style={{display:'none'}}>{`${boshlandi}`}</p>
        <p style={{display:'none'}} className='faceDetection'>{user!==null?`${user.faceDetection}`:'false'}</p>
    </div>
  )
}
