import React, { useEffect } from 'react';
import { setImage } from '../../host/Config';


const CaptureScreenshot = props => {
const {
  t_id,
resetCapture, video, videoWidth, videoHeight
} = props;

const canvasStyle = {
position: 'absolute',
visibilit: 'hidden'
};
useEffect(()=>{
    console.log(video)
    const canvas = document.querySelector('#screenshot_canvas');
    if (video && canvas) {
    canvas.width = videoWidth;
    canvas.height = videoHeight;
    const context = canvas.getContext('2d');
    context.fillRect(0, 0, videoWidth, videoHeight);
    context.drawImage(video, 0, 0, videoWidth, videoHeight);
    
      const img = document.createElement('img');
      img.setAttribute('crossOrigin', 'anonymous');
      img.setAttribute('src', canvas.toDataURL());
    console.log(canvas)
   
    const xhr = new XMLHttpRequest();
  xhr.open('GET', img.src);
  xhr.responseType = 'blob';
  xhr.onload = function() {
  const fileBlob = xhr.response;
  const formData = new FormData();
  formData.append('image', fileBlob);
  setImage(t_id, formData).then(res=>{
    
    console.log(res)
  }).catch(err=>{
    console.log(err)
  })
};
xhr.send();
      img.remove();
    }
    resetCapture();
    
    
}, [])

return (
<>
<canvas id="screenshot_canvas" style={canvasStyle} />
</>
);
};

export default CaptureScreenshot;