import { create } from "zustand";

export const useStore=create((set)=>({
    faceapi:null,
    setfaceapi:(value)=>set(()=>({faceapi:value})),
    faceDetectionLogin:true,
    setfaceDetectionLogin:(value)=>set(()=>({faceDetectionLogin:value})),
    schoolId: // {branch_name_lat:t("Avtomaktabni tanlang"),branch_name_kir:t("Avtomaktabni tanlang"),branch_name_rus:t("Avtomaktabni tanlang"),id:286}
    null,
    setschoolId:(value)=>set(()=>({schoolId:value})),
    test:null,
    settest:(value)=>set(()=>({test:value})),
    tests_last:null,
    settests_last:(value)=>set(()=>({tests_last:value})),
    not_touched_answers:0,
    setnot_touched_answers:(value)=>set(()=>({not_touched_answers:value})),
    correct_answers:0,
    setcorrect_answers:(value)=>set(()=>({correct_answers:value})),
    wrong_answers:0,
    setwrong_answers:(value)=>set(()=>({wrong_answers:value})),
    detectionsImage:null,
    setdetectionsImage:(value)=>set(()=>({detectionsImage:value})),
    video:false,
    setvideo:(value)=>set(()=>({video:value})),
    images:null,
    setimages:(value)=>set(()=>({images:value})),
    schools:[],
    setschools:(value, id)=>set((state)=>{
        var a=state.schools
        if(!a[id]){
           a[id]=value
        }
        console.log(a)
        return({schools:a})
    }),
    level:0,
    setlevel:(value)=>set((state)=>{
        if(value!==null){
            return({level:value})
        }
        else{
            if(state.level!==0){
                return({level:state.level-1})
            }else{
                return({level:state.level})
            }
        }
     }),
    user:null,
    setuser:(value)=>set(()=>({user:value})),
    tests:null,
    settests:(value)=>set(()=>({tests:value})),
    setanswerTests:(q_key, a_id, c_id)=>set((state)=>{
       var a=state.tests
       a.questionsList[q_key].user_answerId=a_id
       a.questionsList[q_key].correct_answerId=c_id
       if(a_id===c_id){
        a.not_touched_answers=a.not_touched_answers-1
        a.correct_answers=a.correct_answers+1
       }else{
        a.not_touched_answers=a.not_touched_answers-1
        a.wrong_answers=a.wrong_answers+1
       }
       return({tests:a})
     }),
    dark:false,
    setdark:(value)=>set((state)=>({dark:value})),
    bigloader:true,
    setbigloader:(value)=>set((state)=>({bigloader:value})),
    token:true,
    settoken:(value)=>set((state)=>({token:value})),
}))