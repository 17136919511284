
import React, { useEffect, useState } from 'react'
import FaceDetection from './FaceDetection'
import { useStore } from '../../store/Store'

import moon from '../../image/moon_test.svg'
import sun from '../../image/sun.svg'
import no_image from '../../image/no_image.svg'
import no_image_dark from '../../image/no_image_dark.svg'
import { useTranslation } from 'react-i18next'
import i18n from '../../locales/i18next'
import ResultModals from './ResultModals'
import { useCookies } from 'react-cookie'
import { getImages, getTest, getTestLast, getUser, setAnswer } from '../../host/Config'
import BigLoader from '../BigLoader'

export default function Test() {
  const faceapi =useStore(state=>state.faceapi)
 const user =useStore(state=>state.user)
 const setimages =useStore(state=>state.setimages)
 const images =useStore(state=>state.images)

 const setanswerTests =useStore(state=>state.setanswerTests)
 const dark =useStore(state=>state.dark)
 const setdark =useStore(state=>state.setdark)
 const bigloader =useStore(state=>state.bigloader)
 const setbigloader =useStore(state=>state.setbigloader)
const tests =useStore(state=>state.tests)
  const settests =useStore(state=>state.settests)
  const setuser =useStore(state=>state.setuser)
  const [selected, setselected]=useState(0)
  // const [checker, setchecker]=useState(null)
  const [checktime, setchecktime]=useState(false)
  const [wrong_answers, setwrong_answers]=useState(0)
  const [correct_answers, setcorrect_answers]=useState(0)
  const [not_touched_answers, setnot_touched_answers]=useState(0)
  const [time, settime]=useState(1500)
  const [btnEnd, setbtnEnd]=useState(false)
  const [axios_send, setaxios_send]=useState(false)
  const [yesNot, setyesNot]=useState(true)
  // const [faceEnd, setfaceEnd]=useState(false)
  const [finishTestBool, setfinishTestBool]=useState(false)
  const [bol, setbol]=useState(false)
  const [confirm_answer, setconfirm_answer]=useState(null)
  const [select_answer, setselect_answer]=useState(null)
  const [select_answer_style, setselect_answer_style]=useState(null)
  const {t}=useTranslation()
  // const [face_error_text, setface_error_text]=useState(null)

  const [successEnd, setsuccessEnd]=useState(false)
  const [errorEnd, seterrorEnd]=useState(false)
 
  const [cookies, setCookie, removeCookie]=useCookies()

  const getTestData=()=>{
    getTest(cookies.id_user).then(res2=>{
      if(res2.data.status===0){
        settests(res2.data)
        setwrong_answers(res2.data.wrong_answers)
        setcorrect_answers(res2.data.correct_answers)
        setnot_touched_answers(res2.data.not_touched_answers)
        if(Number(res2.data.wrong_answers)>2 || Number(res2.data.not_touched_answers)-1===0){
          finishTest(res2.data)
        }
        clearInterval(b)
        var b=setInterval(()=>{
          var start_date=new Date(res2.data.created_on.slice(0, res2.data.created_on.lastIndexOf("+")))
          var now_date=new Date()
          var timeNow=1500-parseInt((now_date.getTime() - start_date.getTime())/1000)
          if(timeNow<0){
            settime(0)
           finishTest(res2.data) 
          }else{
            settime(timeNow)
          }
          timeNow--
          if(document.getElementsByClassName('finishTestBool')[0].innerHTML==="true"){
            if(timeNow<0){
              settime(1500)
            }else{
              settime(1500-timeNow)
            }
            
            clearInterval(b)
         
          }
        }, 1000)
       
      }else{
        
        window.location.href=window.location.href.slice(0, window.location.href.lastIndexOf("/"))
      }
     }).catch(err=>{
      window.location.href=window.location.href.slice(0, window.location.href.lastIndexOf("/"))
        
      })
   }

   const getTestDatafinish=async()=>{
    try{
      var res2=await getTestLast(cookies.id_user)
        settests(res2.data)
        setwrong_answers(res2.data.wrong_answers)
        setcorrect_answers(res2.data.correct_answers)
        setnot_touched_answers(res2.data.not_touched_answers)
        return({w_a:res2.data.wrong_answers, n_t_a:res2.data.not_touched_answers})
    }catch(err){
      console.log('error')   
    }
    
   }

  useEffect(()=>{
    document.addEventListener('keydown', handleKeyPress);
      i18n.changeLanguage(cookies.langTest?cookies.langTest:"o'z")
   
      if((user===null) && (cookies.id_user===undefined)){
     window.location.href=window.location.href.slice(0, window.location.href.lastIndexOf("/"))
      }else{
        if(user===null && cookies.id_user!==undefined){
          setbigloader(true)
          getUser(cookies.id_user).then(res1=>{
            setuser(res1.data)
           }).catch(err=>{
             console.log("salom")
         })
         getTestData()
        
        }
        if(user!==null){
          removeCookie("id_user")
       
          setCookie("id_user", user.id, {
            maxAge:1500
          })
         
          if(tests.status===0){
            setwrong_answers(tests.wrong_answers)
            setcorrect_answers(tests.correct_answers)
            setnot_touched_answers(tests.not_touched_answers)
          
            clearInterval(b)
            var b=setInterval(()=>{
              var start_date=new Date(tests.created_on.slice(0, tests.created_on.lastIndexOf("+")))
              var now_date=new Date()
              
              var timeNow=1500-parseInt((now_date.getTime() - start_date.getTime())/1000)
              if(timeNow<0){
                settime(0)
                finishTest()
                }else{
                settime(timeNow)
               }
               timeNow--
               if(document.getElementsByClassName('finishTestBool')[0].innerHTML==="true"){
                if(timeNow<0){
                  settime(1500)
                }else{
                  settime(1500-timeNow)
                }
                clearInterval(b)
              }
             }, 1000)
         
        
          }else{
            window.location.href=window.location.href.slice(0, window.location.href.lastIndexOf("/"))
          }
         
        }
       
        } i18n.changeLanguage(cookies.langTest?cookies.langTest:"o'z")
   
   
  
return(()=>{
      document.removeEventListener('keydown', handleKeyPress);
    })
    },[])
  
    function handleKeyPress(event, test) {
    
      var a=112
     
      var teg=document.getElementsByClassName('confirm_answer_bool')[0].innerHTML==='true'
     
      if(event.keyCode===37){
        if(teg){
          setyesNot(false)
         }else{
          if(Number(document.getElementsByClassName("selected_selected")[0].innerHTML)!==0){
            setselected(Number(document.getElementsByClassName("selected_selected")[0].innerHTML)-1)
          }
         }
      
      }
      if(event.keyCode===39){
        if(teg){
          setyesNot(true)
         }else{
          if(Number(document.getElementsByClassName("selected_selected")[0].innerHTML)!==Number(document.getElementsByClassName("test_length")[0].innerHTML)){
            setselected(Number(document.getElementsByClassName("selected_selected")[0].innerHTML)+1)
          }
         }
      

      }
      if(event.keyCode===13){
        if(teg){
          if(document.getElementsByClassName('yesNot')[0].innerHTML==='true'){
        
            setAnswerFuncApi({uq_id:Number(document.getElementsByClassName('selected_test')[0].innerHTML), q_u:Number(document.getElementsByClassName('selected_question')[0].innerHTML), a_id:Number(document.getElementsByClassName('select_answer_id')[0].innerHTML), timer:Number(document.getElementsByClassName('selected_time')[0].innerHTML)})
          }else{
            setconfirm_answer(null); setselect_answer(null)
          }
        }
      }
      if (event.keyCode === 116 || event.keyCode === 112 || event.keyCode === 113 || event.keyCode === 114 || event.keyCode === 115 || event.keyCode === 116 || event.keyCode === 117 || event.keyCode === 118 || event.keyCode === 119 || event.keyCode === 120  || event.keyCode === 121  || event.keyCode === 122 || event.keyCode === 123) { // Check if F5 was pressed
        event.preventDefault();
        if(document.getElementsByClassName('checker')[0].innerHTML==='false') {
          var selected_length=Number(document.getElementsByClassName('selected_length')[0].innerHTML)
          var selected_test=Number(document.getElementsByClassName('selected_test')[0].innerHTML)
          var selected_answer=Number(document.getElementsByClassName('selected_answer')[0].innerHTML)
          var selected_question=Number(document.getElementsByClassName('selected_question')[0].innerHTML)
          var selected_time=Number(document.getElementsByClassName('selected_time')[0].innerHTML)
          
        if((event.keyCode-a)<selected_length && selected_answer===0){
              setselect_answer_style(event.keyCode-a)
              
              setAnswerFunc(selected_test,selected_answer, selected_question, document.querySelectorAll('.test_answers_box div')[event.keyCode-a].id, selected_time)
              setTimeout(() => {
                setselect_answer_style(null)
              }, 300);
              
        }
        }
      }
     
}

  //  const faceEndTest=()=>{
  // setfaceEnd(true)
  //  }
   const setAnswerFunc=(uq_id, q_a_id, q_u, a_id, timer)=>{
 
 var not_touched_answers=Number(document.getElementsByClassName('not_touched_answers')[0].innerHTML)

if((not_touched_answers-1)%5===0 || not_touched_answers===20){
 setbol(true)
}
if(q_a_id===0 && document.getElementsByClassName('finishTestBool')[0].innerHTML==="false"){
      setselect_answer(Number(a_id))
      setconfirm_answer({uq_id, q_u, a_id, timer})
    setyesNot(true)
  
    }
  }
    const addSelected=()=>{
      if(selected!==19){
        setselected(selected+1)
      }else{
        setselected(0)
      }
    }
    const removeSelected=()=>{
      if(selected!==0){
        setselected(selected-1)
      }else{
        setselected(19)
      }
    }
    const selectSelected=(key)=>{
     setselected(key)
    }
    const endTest=()=>{
      if(document.getElementsByClassName('finishTestBool')[0].innerHTML==="false"){
        var n_t_a=Number(document.getElementsByClassName("not_touched_answers")[0].innerHTML)
        if(n_t_a>0){
            setbtnEnd(true)
        }else{
          finishTest()
        }
      }else{
        window.location.href=window.location.href.slice(0, window.location.href.lastIndexOf("/"))
      }
     
     }
     const finishTest=async(a)=>{
      // setfaceEnd(false)
     var items_datas=await getTestDatafinish()
     var id=null
      if(!!a){
        id=a.id
      }else{
    
        id=Number(document.getElementsByClassName("selected_test")[0].innerHTML)
      }
      
      setAnswer(id, 0,0,0).then(res=>{
        
        var w_a=items_datas.w_a
        var n_t_a=items_datas.n_t_a
         setfinishTestBool(true)
         getImages(id).then(res2=>{
          setimages(res2.data)
         }).catch(err=>{
          console.log(err)
         })
         
        if(n_t_a+w_a>2){
          seterrorEnd(true)
        }else{
          setsuccessEnd(true)
        }
     
      }).catch(err=>{
        console.log(err)
      })
     
     }
    const questionTiltle=(a)=>{
       var b=JSON.parse(a)
       var a={
        "ru":b.ru,
      "uz":b.uzb,
      "krill":b.uzc,
       }
       return(a)
    }
    
    const setAnswerFuncApi=({uq_id, q_u, a_id, timer})=>{
     setaxios_send(true)
      setAnswer(uq_id, q_u, a_id, timer).then(res=>{
        if(res.data!==-404){
          setanswerTests(Number(document.getElementsByClassName("selected_selected")[0].innerHTML), Number(a_id), res.data)
         if(res.data!==a_id){
          var wr=Number(document.getElementsByClassName("wrong_answers")[0].innerHTML)+1
          var nr=Number(document.getElementsByClassName("not_touched_answers")[0].innerHTML)-1
          setwrong_answers(Number(document.getElementsByClassName("wrong_answers")[0].innerHTML)+1)
            setnot_touched_answers(Number(document.getElementsByClassName("not_touched_answers")[0].innerHTML)-1)
            if(wr>2 || nr===0){
              finishTest()
            }
          }
          if(res.data===a_id){
            var wr=Number(document.getElementsByClassName("wrong_answers")[0].innerHTML)
            var nr=Number(document.getElementsByClassName("not_touched_answers")[0].innerHTML)-1
            setcorrect_answers(Number(document.getElementsByClassName("correct_answers")[0].innerHTML)+1)
            setnot_touched_answers(Number(document.getElementsByClassName("not_touched_answers")[0].innerHTML)-1)
            if(wr>2 || nr===0){
              finishTest()
            }
          }
          setconfirm_answer(null)
          setselect_answer(null)
          setTimeout(()=>{
           if(Number(document.getElementsByClassName("selected_selected")[0].innerHTML)!==Number(document.getElementsByClassName("test_length")[0].innerHTML)){
              setselected(Number(document.getElementsByClassName("selected_selected")[0].innerHTML)+1)
            }
          },1000)
        }else{
          setconfirm_answer(null)
          setselect_answer(null)
        }
        if(Number(document.getElementsByClassName("wrong_answers")[0].innerHTML)+Number(document.getElementsByClassName("correct_answers")[0].innerHTML)===20){
          finishTest()
        }
        setaxios_send(false)
        }).catch(err=>{
          setconfirm_answer(null)
          setselect_answer(null)
          setaxios_send(false)
        })

      }
  return (
    <>
    <p className='selected_length' style={{display:'none'}}>{tests!==null?tests.questionsList[selected].testAnswers.length:''}</p>
        <p className='selected_answer' style={{display:'none'}}>{tests!==null?tests.questionsList[selected].user_answerId:''}</p>
        <p className='selected_question' style={{display:'none'}}>{tests!==null?tests.questionsList[selected].id:''}</p>
        <p className='test_length' style={{display:'none'}}>{tests!==null?tests.questionsList.length-1:''}</p>
        <p className='selected_test' style={{display:'none'}}>{tests!==null?tests.id:''}</p>
        <p className='selected_time' style={{display:'none'}}>{time}</p>
        <p className='selected_selected' style={{display:'none'}}>{selected}</p>
        <p className='wrong_answers' style={{display:'none'}}>{wrong_answers}</p>
        <p className='correct_answers' style={{display:'none'}}>{correct_answers}</p>
        <p className='checker' style={{display:'none'}}>{checktime?'true':'false'}</p>
        <p className='not_touched_answers' style={{display:'none'}}>{not_touched_answers}</p>
        <p className='finishTestBool' style={{display:'none'}}>{`${finishTestBool}`}</p>
        <p className='confirm_answer_bool' style={{display:'none'}}>{`${confirm_answer!==null}`}</p>
        <p className='yesNot' style={{display:'none'}}>{`${yesNot}`}</p>
        <p className='select_answer_id' style={{display:'none'}}>{`${select_answer}`}</p>
  <>
    {/* {user===null?<Navigate to="/"/>:<></>} */}
    {bigloader?<BigLoader/>:<></>}
   <div className='test'>
    <div className='test_logo'>
     
    </div>
     
    <div className='test_number'>
    <div className='test_arrow test_arrow_left'  onClick={()=>{if(!axios_send){removeSelected()}}}></div>
    <div className='test_number_box'>
    {tests!==null && !!tests.questionsList?tests.questionsList.map((item, key)=>{
      return(<div key={key} onClick={()=>{if(!axios_send){selectSelected(key)}}} className={` test_number_item ${item.correct_answerId!==0 && item.correct_answerId!==item.user_answerId?'test_number_error':''} ${key===selected?'test_number_select':''} ${item.correct_answerId!==0 && item.correct_answerId===item.user_answerId?'test_number_success':''}`}>{key+1}</div>)
    }):<></>}
    </div>
    <div className='test_arrow test_arrow_right'  onClick={()=>{if(!axios_send){addSelected()}}}></div>
</div>
<div className={`test_header_params ${finishTestBool?"finished_test_header":""}`}>
  <div className={`test_lang ${t('lang')==='lang'?'uz':t('lang')}`}>
    <div  onClick={()=>{i18n.changeLanguage("o'z")}} className='test_lang_item uz'>O'Z</div>
    <div  onClick={()=>{i18n.changeLanguage("ўз")}} className='test_lang_item krill'>ЎЗ</div>
    <div onClick={()=>{i18n.changeLanguage("ру")}} className='test_lang_item ru'>РУ</div>
  </div>
  {finishTestBool? <div onClick={()=>{setdark(!dark)}} className='dark_item'>
    <img src={dark?sun:moon}/>
  </div>:<></>}
  <div className={`time ${finishTestBool?'A006ecb':time<=15*60 && time>5*60?"FFB501":time<=5*60?"E73232":"A6EA903"}`}>
    <h1>
     
    <span>{parseInt(parseInt(time/60)/10)}</span>
    <span>{parseInt(parseInt(time/60)%10)}</span>
    <span>:</span>
    <span>{parseInt(parseInt(time%60)/10)}</span>
    <span>{parseInt(parseInt(time%60)%10)}</span>
    </h1>
    <div className='time_bg' style={{width:`${time*100/(25*60)}%`, background:time<=15*60 && time>5*60?"#FFB501":time<=5*60?"#E73232":"#6EA903"}}></div>
  </div>
  {!finishTestBool? <div onClick={()=>{setdark(!dark)}} className='dark_item'>
    <img src={dark?sun:moon}/>
  </div>:<></>}
  {finishTestBool?<div className={`final_btn chiqish`} onClick={()=>{endTest()}}>
   {t("Chiqish")}
  </div>:<></>}
</div>
<div className='test_video'>
  <div className='test_video_cam'><FaceDetection 
  // checker={checker} setchecker={setchecker} 
  time={time}
  images={images}
  checktime={checktime}
  setchecktime={setchecktime}
  // faceEndTest={faceEndTest} 
  // setface_error_text={setface_error_text} 
  finishTestBool={finishTestBool} 
  t_id={tests!==null?tests.id:null} bol={bol} setbol={setbol} faceapi={faceapi}/></div>
  <div className='test_video_text'>
    {user!==null?<><h1>{user.lastName[0].toUpperCase()}{user.lastName.slice(1).toLowerCase()} {user.firstName[0].toUpperCase()}{user.firstName.slice(1).toLowerCase()}</h1><p>{user.phone}</p></>:''}
    
    </div>
   
   </div>
   <div className={`${checktime?'hidden_text':''} test_question`}>
    <div className={`question_card ${tests!==null && !!tests.questionsList?tests.questionsList[selected].user_answerId===0?"":tests.questionsList[selected].user_answerId===tests.questionsList[selected].correct_answerId?"question_card_correct":"question_card_error":''}`}>{selected+1}</div>
    <p dangerouslySetInnerHTML={{__html:tests!==null && !!tests.questionsList?questionTiltle(tests.questionsList[selected].question)[t("lang")]:''}}></p>
  </div>
   <div className='test_image'>
    <img src={tests!==null && !!tests.questionsList?tests.questionsList[selected].media!==null && tests.questionsList[selected].media!==''?tests.questionsList[selected].media:dark?no_image_dark:no_image:dark?no_image_dark:no_image}/>
   </div>
   <div className='test_answers'>
   {tests!==null && !!tests.questionsList?tests.questionsList[selected].testAnswers.map((item, key)=>{
              return(
              <div  key={key} className='test_answers_box'>
                <p>F{key+1}</p>
              <div id={`${item.id}`} onClick={()=>{setAnswerFunc(tests.id, tests.questionsList[selected].user_answerId, tests.questionsList[selected].id, item.id, time)}} key={key} dangerouslySetInnerHTML={{__html:questionTiltle(item.answer)[t("lang")]}} className={`${checktime?'hidden_text':''} test_answers_item ${tests.questionsList[selected].user_answerId===0 && (!finishTestBool)?"t_a":""} ${!finishTestBool && select_answer_style===key?"p_a":""} ${tests.questionsList[selected].correct_answerId!==0 && tests.questionsList[selected].user_answerId===tests.questionsList[selected].correct_answerId && tests.questionsList[selected].correct_answerId===item.id?'success_answer':""} ${tests.questionsList[selected].correct_answerId!==0 && item.id===tests.questionsList[selected].user_answerId?'error_answer':""} ${select_answer===item.id?"select_answer":''}`}></div></div>)
      }):<></>}
      
   </div>
   </div>
<ResultModals 
wrong_answers={wrong_answers}
correct_answers={correct_answers}
not_touched_answers={not_touched_answers}
btnEnd={btnEnd}
successEnd={successEnd}
errorEnd={errorEnd}
setbtnEnd={setbtnEnd}
time={time}

setsuccessEnd={setsuccessEnd}
seterrorEnd={seterrorEnd}
finishTest={finishTest}
// face_error_text={face_error_text}
// faceEnd={faceEnd}
/></>
<div className={`confirm_answer ${confirm_answer!==null?"confirm_answer_open":""}`}>
  
         <div className='confirm_answer_body'>
          <p>{t("confirm")}</p>
          <div className='confirm_btn'>
            <button className={!yesNot?"select_btn_error":''} onClick={()=>{setconfirm_answer(null); setselect_answer(null)}}>{t("Yo'q")}</button>
            <button className={yesNot?"select_btn_success":''} onClick={()=>{setAnswerFuncApi(confirm_answer)}}>{t("Ha")}</button>
          </div>
         </div>
        </div>
        
        
        
    </>
   
  )
}
